import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerRoot, DrawerTitle } from 'components/ui/drawer';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

export const LiveDataSourceConnectModal = () => {
  const { orgId } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();
  const [, setSearchParams] = useQueryParam('switchOrg');
  const [liveSource, setLiveSource] = useQueryParam('liveSource');

  const handleClose = () => {
    onClose();
    setSearchParams({});
  };

  const handleClick = () => {
    handleClose();
    setSearchParams({ switchOrg: orgId });
  };

  useEffect(() => {
    if (!open && liveSource) {
      onOpen();
    }
    () => {
      setLiveSource({});
    };
  }, [liveSource]);

  return (
    <DrawerRoot open={open}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Want to connect live data source?</DrawerTitle>
        </DrawerHeader>
        <DrawerBody>
          <Text>
            You are trying to connect a live data source. If you&apos;re ready to go live, switch to live organization
            from organization switcher.
          </Text>
        </DrawerBody>
        <DrawerFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={handleClick}>
              Switch Organization
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </DrawerRoot>
  );
};

import { useCallback, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

type SortDirection = 'asc' | 'desc';

interface UseColumnSortingProps {
  allSortableFields: string[];
  fieldMapping: Record<string, string>;
  setOrder: (newOrder?: string[]) => void;
}

/**
 * A custom hook that replicates the sorting behavior from `useSortableTable`
 * If you do not want to use searchParams to sort the data, you can use this hook to sort the data.
 *
 * @param allSortableFields - The fields that can be sorted
 * @param fieldMapping - The mapping of the field to the actual field name
 * @param setOrder - The function to set the order of the data
 */

export function useColumnSorting({ allSortableFields, fieldMapping, setOrder }: UseColumnSortingProps) {
  // We store the combined orderBy string, e.g. "-start_date,end_date"
  const [orderBy, setOrderBy] = useState('');

  /**
   * Helper: parse orderBy string to see if a given field is 'asc' or 'desc'.
   * Example: if orderBy === '-start_date,end_date', then
   *  getSortOrder('start_date') = 'desc'
   *  getSortOrder('end_date')   = 'asc'
   */
  const getSortOrder = useCallback(
    (field: string): SortDirection => {
      if (!orderBy) return 'asc';
      const fieldsArray = orderBy.split(',').map(f => f.trim());
      for (const f of fieldsArray) {
        const desc = f.startsWith('-');
        const pureName = desc ? f.slice(1) : f;
        if (pureName === field) {
          return desc ? 'desc' : 'asc';
        }
      }
      return 'asc';
    },
    [orderBy]
  );

  /**
   * handleSort replicates the prefix logic from `useSortableTable`.
   * - If the field is currently 'asc', switch it to '-' (desc).
   * - Otherwise set it to '' (asc).
   * - For any other fields that are found to be 'desc', keep '-' (desc).
   */
  const handleSort = useCallback(
    (headerLabel: string) => {
      const actualFieldKey = fieldMapping[headerLabel] ?? headerLabel;
      const currentOrder = getSortOrder(actualFieldKey);
      const togglePrefix = currentOrder === 'asc' ? '-' : '';

      const sortedFields = allSortableFields.map(header => {
        const mapped = fieldMapping[header] ?? header;
        if (mapped === actualFieldKey) {
          return `${togglePrefix}${mapped}`;
        }
        return getSortOrder(mapped) === 'desc' ? `-${mapped}` : mapped;
      });

      const newOrderByArray = sortedFields.filter(Boolean);
      const newOrderBy = newOrderByArray.join(',');

      setOrderBy(newOrderBy);
      setOrder(newOrderByArray);

      return '';
    },
    [allSortableFields, fieldMapping, getSortOrder, setOrder]
  );
  const getSortIcon = useCallback(
    (headerLabel: string) => {
      const actualField = fieldMapping[headerLabel] ?? headerLabel;
      const direction = getSortOrder(actualField);
      return direction === 'asc' ? <MdArrowDropUp /> : <MdArrowDropDown />;
    },
    [fieldMapping, getSortOrder]
  );

  return {
    orderBy,
    handleSort,
    getSortIcon,
    getSortOrder, // optional expose if needed
  };
}

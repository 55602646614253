import { RedirectToLogin, useAuthInfo } from '@propelauth/react';
import { useEffect } from 'react';

import { ACLProvider } from './acl/acl';
import { PayWallProvider } from './acl/paywall';
import AppLoader from './app-loader';
import { AppLoaderWrapper, AppLoaderWrapperProvider } from './app-loader-wrapper';
import AppRoutes from './app-routes';

const APP_URL = import.meta.env.VITE_APP_URL || 'https://app.trykintsugi.com';

export const AppContent = ({ isEmbeddedInIframe }: { isEmbeddedInIframe: boolean }): React.ReactNode => {
  const { isLoggedIn, loading } = useAuthInfo();

  const openInNewTab = () => {
    const newTab = window.open(window.location.href, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  useEffect(() => {
    if (isEmbeddedInIframe && !isLoggedIn && !loading) {
      openInNewTab();
    }
  }, [isEmbeddedInIframe, isLoggedIn, loading]);

  if (loading) {
    return <AppLoader />;
  }

  if (!isLoggedIn) {
    return isEmbeddedInIframe ? (
      <div
        style={{
          backgroundColor: '#e8f0fe',
          borderRadius: '4px',
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <div
          style={{
            backgroundColor: '#4285f4',
            color: 'white',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          i
        </div>
        <p
          style={{
            margin: '0',
            color: '#202124',
          }}
        >
          Please log into Kintsugi in the new tab that has been opened or{' '}
          <a
            style={{
              color: '#1a73e8',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
            href={APP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
          . Once you logged in, you can return to Shopify and use Kintsugi app inside your store.
        </p>
      </div>
    ) : (
      <RedirectToLogin />
    );
  }

  return (
    <ACLProvider>
      <PayWallProvider>
        <AppLoaderWrapperProvider>
          <AppLoaderWrapper>
            <AppRoutes />
          </AppLoaderWrapper>
        </AppLoaderWrapperProvider>
      </PayWallProvider>
    </ACLProvider>
  );
};

import { Flex, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

import IntercomWidget from './intercom';

export const ErrorFallback = () => {
  return (
    <Flex
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Text fontSize="22px" fontWeight={500} mb={4}>
        Oops! Something went wrong
      </Text>

      <Text color="gray.600" mb={6}>
        Don&apos;t worry, you can still navigate to other pages
      </Text>

      <IntercomWidget support={true} />
    </Flex>
  );
};

export const { ErrorBoundary, withErrorBoundary } = Sentry;

import { Flex, Table, Text } from '@chakra-ui/react';
import { sendRegistrationPassword } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import { KebabIcon, WarningIcon } from 'components/icons';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { RegistrationInstance, RegistrationStatus } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { getFilingFreq } from 'utils/enum-helpers';
import { formatCurrency } from 'utils/utils';

import { DeRegistration } from './de-registration';
import { EditRegistrationForm } from './edit-imported-registration';
import RegistrationStateBadge from './registration-state-badge';
import { VDAProcessNeeded } from './vda-process-needed';

type RegistrationTableRowProps = RegistrationInstance & { visibleColumns?: string[] };

const RegistrationTableRow = ({
  id,
  country_code,
  state_code,
  state_name,
  registration_date,
  registration_email,
  filing_frequency,
  filing_days,
  status,
  imported,
  username,
  has_all_credentials,
  amount_fees,
  visibleColumns,
  vda,
}: RegistrationTableRowProps) => {
  const isRegistered = status === RegistrationStatus.REGISTERED;
  const isImporting = status === RegistrationStatus.VALIDATING || status === RegistrationStatus.AWAITING_CLARIFICATION;
  const { orgId } = useOrg();
  const { isSubscriptionPausedOrCanceled } = usePaywall();

  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const [confirmDeRegistration, setConfirmDeRegistration] = useState<string | null>(null);
  const [confirmEditRegistration, setConfirmEditRegistration] = useState<string | null>(null);

  const handleSendPassword = async (registration_id: string) => {
    try {
      const response = await sendRegistrationPassword(orgId, registration_id);
      if (response.status === 200) {
        handleSuccessNotification('Check your email to get the credentials for this jurisdiction.');
      } else {
        toaster.create({
          title: 'Username and/or password not found',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error while getting password:', error);
      handleFailNotification(error);
    }
  };

  const editImportedMenuContent = (
    <MenuRoot
      onSelect={({ value }) => {
        if (value === 'editImportedRegistration') {
          setConfirmEditRegistration(id);
        }
      }}
    >
      <MenuTrigger>
        <KebabIcon />
      </MenuTrigger>
      <MenuContent>
        <MenuItem value="editImportedRegistration">Edit</MenuItem>
      </MenuContent>
    </MenuRoot>
  );

  const registerMenuContent = (
    <MenuRoot
      onSelect={({ value }) => {
        if (value === 'getPassword') {
          handleSendPassword(id);
        } else if (value === 'deRegister') {
          setConfirmDeRegistration(id);
        }
      }}
    >
      <MenuTrigger>
        <KebabIcon />
      </MenuTrigger>
      <MenuContent>
        <MenuItem value="getPassword" disabled={!has_all_credentials}>
          <Tooltip content={'Credentials unavailable; please contact support.'} disabled={has_all_credentials}>
            <Text>Get Credentials</Text>
          </Tooltip>
        </MenuItem>

        <MenuItem disabled={isSubscriptionPausedOrCanceled} value="deRegister">
          <Tooltip
            content={'You need to activate subscription for de-register'}
            disabled={!isSubscriptionPausedOrCanceled}
          >
            <Text>De-register</Text>
          </Tooltip>
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  );

  return (
    <>
      <Table.Row>
        {visibleColumns?.includes('Country') && (
          <Table.Cell>
            <Text lineClamp={1}>{getCountryDisplay(country_code)}</Text>
          </Table.Cell>
        )}
        {visibleColumns?.includes('Jurisdiction') && (
          <Table.Cell>
            <Flex gap={2}>
              <Text lineClamp={1}>{state_code}</Text>
              {status === RegistrationStatus.AWAITING_CLARIFICATION && (
                <Tooltip
                  content={
                    'We await your input before proceeding with your highlighted requests on this page. Please respond to support.'
                  }
                >
                  <WarningIcon />
                </Tooltip>
              )}
            </Flex>
          </Table.Cell>
        )}
        {visibleColumns?.includes('Registration Date') && (
          <Table.Cell>
            <Text lineClamp={1}>{registration_date}</Text>
          </Table.Cell>
        )}
        {visibleColumns?.includes('Registration Email') && (
          <Table.Cell>
            <Text>{registration_email}</Text>
          </Table.Cell>
        )}
        {visibleColumns?.includes('Filing Frequency') && (
          <Table.Cell>
            <Text lineClamp={1}>{getFilingFreq(filing_frequency)}</Text>
          </Table.Cell>
        )}

        {visibleColumns?.includes('Registration Fees') && (
          <Table.Cell textAlign="end">
            <Text>{formatCurrency(amount_fees)}</Text>
          </Table.Cell>
        )}
        {visibleColumns?.includes('Status') && (
          <Table.Cell>
            <Flex align={'center'} gap={2}>
              <Tooltip
                disabled={status !== RegistrationStatus.VALIDATING}
                content="We'll update the status to 'Registered' once we've validated your submitted details. We may contact you if required"
              >
                <RegistrationStateBadge status={status} />
              </Tooltip>
              <VDAProcessNeeded vda={vda} isRegistered={status === RegistrationStatus.REGISTERED} />
            </Flex>
          </Table.Cell>
        )}

        <Table.Cell>
          <Flex align={'center'} flexWrap="nowrap">
            {status !== RegistrationStatus.PROCESSING && (
              <>
                {isRegistered && registerMenuContent}
                {!isRegistered && isImporting && editImportedMenuContent}
              </>
            )}
          </Flex>
        </Table.Cell>
      </Table.Row>

      {confirmDeRegistration && (
        <DeRegistration
          registrationId={confirmDeRegistration}
          onClose={() => {
            setConfirmDeRegistration(null);
          }}
        />
      )}

      {confirmEditRegistration && (
        <EditRegistrationForm
          payload={{
            id,
            username,
            country_code,
            state_code,
            state_name,
            registration_date,
            registration_email,
            filing_frequency,
            filing_days,
            status,
            imported,
            has_all_credentials,
          }}
          onClose={() => {
            setConfirmEditRegistration(null);
          }}
        />
      )}
    </>
  );
};

export default RegistrationTableRow;

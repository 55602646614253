import { Input, InputProps } from '@chakra-ui/react';
import React, { useState } from 'react';

type SmartInputProps = {
  shouldMatchPattern: boolean;
  pattern: string;
  onPatternMatched: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<InputProps, 'onChange'>;

export const SmartInput = ({ shouldMatchPattern, pattern, onPatternMatched, onChange, ...rest }: SmartInputProps) => {
  const [hasMatched, setHasMatched] = useState<boolean>(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    const { value } = e.target;

    if (value.trim() === '') {
      setHasMatched(false);
      return;
    }

    if (shouldMatchPattern && !hasMatched) {
      const regex = new RegExp(pattern);
      if (regex.test(value)) {
        onPatternMatched();
        setHasMatched(true);
      }
    }
  };

  return <Input {...rest} onChange={handleOnChange} />;
};

SmartInput.displayName = 'SmartInput';

import AlertBanner from 'components/alert/alert';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useState } from 'react';
import { RegistrationInstance } from 'types/shared-types';

import RegistrationTableRow from './registration-table-row';

type RegistrationsTableProps = {
  data: RegistrationInstance[];
  hasAwaitingClarification: boolean;
  isPending: boolean;
};

const TABLE_HEAD = [
  'Country',
  'Jurisdiction',
  'Registration Date',
  'Registration Email',
  'Filing Frequency',
  'Registration Fees',
  'Status',
];

const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;

const NUMERIC_COL = ['Registration Fees'];

const COLUMN_WIDTHS = {
  Country: '150px',
  Jurisdiction: '100px',
  'Registration Date': '110px',
  'Registration Email': '130px',
  'Filing Frequency': '100px',
  'Registration Fees': '150px',
  Status: '150px',
};

export const RegistrationsTable = ({ data, hasAwaitingClarification, isPending }: RegistrationsTableProps) => {
  const isDataEmpty = !data || data.length === 0;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleVisibleColumnsChange = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  if (isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Registrations"
        tableUniqueMessage="No Registrations Exist"
        uniqueHelperTxt="Please register in a jurisdiction or import existing registrations to generate filings."
      />
    );
  }

  return (
    <>
      {hasAwaitingClarification && (
        <AlertBanner
          message=" We await your input before proceeding with your highlighted requests on this page. Please respond to
            support."
          width={{ xl: '75%' }}
        />
      )}

      <KDataTable
        headers={TABLE_HEAD}
        numericCols={NUMERIC_COL}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        showColumnFilter
        columnWidths={COLUMN_WIDTHS}
        isPending={isPending}
      >
        {data.map((row: RegistrationInstance) => (
          <RegistrationTableRow key={row.id} {...row} visibleColumns={visibleColumns} />
        ))}
        {isPending && <TableRowSkeleton length={25} columns={visibleColumns} />}
      </KDataTable>
    </>
  );
};

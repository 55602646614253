import { Flex, List, Spacer, Text, VStack } from '@chakra-ui/react';
import { getBillingCheckoutHostedPage } from 'apis/billing-apis';
import AlertBanner from 'components/alert/alert';
import { Button } from 'components/ui/button';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import useTracking from 'hooks/useTracking';
import debounce from 'lodash/debounce';
import { MdCheck } from 'react-icons/md';
import { BillingPlan } from 'types/billings';
import { chargebee } from 'utils/chargebee';

type PricingCardProps = BillingPlan & {
  onClose: () => void;
  onUpgradeSuccess: () => void;
};

export const PricingCard = ({ plan, title, price, features, info, onClose, onUpgradeSuccess }: PricingCardProps) => {
  let hasSuccessfullyCheckedOut = false;
  const { orgId, isTest } = useOrg();
  const { track } = useTracking();
  const { handleFailNotification } = useHandleNotification();
  const [, setSearchParams] = useQueryParam('');

  const handleUpgrade = async () => {
    onClose();

    const chargebeeInstance = await chargebee(isTest);
    chargebeeInstance?.openCheckout({
      layout: 'in_app',
      hostedPage: async () => {
        return await getBillingCheckoutHostedPage(orgId, plan);
      },
      error: error => {
        handleFailNotification(error);
      },
      close: () => {
        if (hasSuccessfullyCheckedOut) {
          track('user upgraded billing plan', {
            updated_billing_plan: plan,
          });
          onUpgradeSuccess();
          setSearchParams({ showAutoFileReg: 'true' });
        }
        chargebeeInstance?.closeAll();
      },
      step: (currentStep: string) => {
        console.log('currentStep', currentStep);
      },
      success: () => {
        hasSuccessfullyCheckedOut = true;
      },
    });
  };

  const debouncedHandleUpgrade = debounce(handleUpgrade, 500);

  return (
    <Flex borderRadius={'8px'} border={'1px solid #EFEFF3'} p={6} direction={'column'} gap={4} width={'350px'}>
      <Flex justify={'center'} align={'center'} direction={'column'} gap={4}>
        <Text fontSize={'1.25rem'} fontWeight="500">
          {title}
        </Text>
        <Text
          fontSize={'2.25rem'}
          fontWeight="500"
          textAlign="center"
          css={plan === 'PREMIUM' ? { lineHeight: '2.5rem' } : {}}
        >
          {price}
        </Text>
      </Flex>
      <VStack pt={4}>
        <List.Root gap={2} w="full">
          {features.map((feature, index) => (
            <List.Item key={index} w={'max-content'} color={'#262B47'} fontSize="sm">
              <List.Indicator color="#4285F4">
                <MdCheck />
              </List.Indicator>
              {feature}
            </List.Item>
          ))}
        </List.Root>
      </VStack>
      {info && <AlertBanner message={info} width={'302px'} />}
      <Spacer />
      <Button borderColor={'#4285F4'} fontSize={'md'} width={'302px'} height={'50px'} onClick={debouncedHandleUpgrade}>
        Upgrade to {title}
      </Button>
    </Flex>
  );
};

export const shadows = {
  xs: { value: '0 0 0 1px rgba(0, 0, 0, 0.05)' },
  sm: { value: '0 1px 2px 0 rgba(0, 0, 0, 0.05)' },
  base: { value: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)' },
  md: { value: '0 4px 6px -1px #1718181F, 0 2px 4px -1px #17181814' },
  lg: { value: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' },
  xl: { value: '0 20px 25px -5px #1718181F, 0 10px 10px -5px #17181814' },
  '2xl': { value: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' },
  outline: { value: '0 0 0 3px rgba(66, 153, 225, 0.6)' },
  inner: { value: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)' },
  none: { value: 'none' },
  'dark-lg': {
    value: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
  },
};

import { Flex, Table, Text, useDisclosure } from '@chakra-ui/react';
import { FilingInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import FilingDetailsDrawer from '../filing-details-drawer';

const FilingHistoryTableRow = ({
  id,
  start_date,
  end_date,
  date_filed,
  total_tax_liability,
  state_name,
}: FilingInstance) => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Table.Row
        cursor={'pointer'}
        onClick={onOpen}
        _hover={{
          bg: '#EFEFF3',
        }}
      >
        <Table.Cell width={{ sm: '150px' }}>
          <Text fontWeight={400}>{state_name}</Text>
        </Table.Cell>
        <Table.Cell width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(start_date)}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(end_date)}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(date_filed)}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '100px' }} textAlign="end">
          <Text>{formatCurrency(total_tax_liability)}</Text>
        </Table.Cell>
      </Table.Row>
      {open && <FilingDetailsDrawer isOpenDrawer={open} onCloseDrawer={onClose} selectedFilingId={id} />}
    </>
  );
};

export default FilingHistoryTableRow;

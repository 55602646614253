export const convertPxToRem = (pxValue = 0) => `${pxValue * 0.0625}rem`;

export const typography = {
  fonts: {
    heading: { value: "'Roboto', sans-serif" },
    body: { value: "'Roboto', sans-serif" },
  },
  fontSizes: {
    xxs: { value: convertPxToRem(10) },
    xs: { value: convertPxToRem(12) },
    sm: { value: convertPxToRem(14) },
    md: { value: convertPxToRem(16) },
    lg: { value: convertPxToRem(18) },
    xl: { value: convertPxToRem(20) },
    '2xl': { value: convertPxToRem(24) },
    '3xl': { value: convertPxToRem(28) },
    '4xl': { value: convertPxToRem(36) },
    '5xl': { value: convertPxToRem(48) },
    '6xl': { value: convertPxToRem(64) },
  },
  fontWeights: {
    normal: { value: 400 },
    medium: { value: 500 },
    bold: { value: 700 },
  },
  lineHeights: {
    normal: { value: 'normal' },
    none: { value: '1' },
    shorter: { value: '1.25' },
    short: { value: '1.375' },
    base: { value: '1.5' },
    tall: { value: '1.625' },
    taller: { value: '2' },
  },
  letterSpacings: {
    tighter: { value: '-0.05em' },
    tight: { value: '-0.025em' },
    normal: { value: '0' },
    wide: { value: '0.025em' },
    wider: { value: '0.05em' },
    widest: { value: '0.1em' },
  },
};

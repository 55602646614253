import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useColumnSorting } from 'hooks/useColumnSorting';
import { FilingInstance } from 'types/shared-types';

import FilingHistoryTableRow from './history-filing-row';

const TABLE_HEAD = ['Jurisdiction', 'Start Date', 'End Date', 'Filed Date', 'Total Tax Liability'];
const NUMERIC_COLUMNS = ['Total Tax Liability'];
const SORT_HEADERS = ['Start Date', 'End Date', 'Total Tax Liability', 'Filed Date'];

const FIELD_MAPPING = {
  'Start Date': 'start_date',
  'End Date': 'end_date',
  'Total Tax Liability': 'total_tax_liability',
  'Filed Date': 'date_filed',
};

export const FilingHistoryTable = ({
  isPending,
  data,
  updateOrder,
}: {
  data: FilingInstance[];
  isPending: boolean;
  updateOrder: (order: string[]) => void;
}) => {
  const { handleSort, getSortIcon } = useColumnSorting({
    allSortableFields: SORT_HEADERS,
    fieldMapping: FIELD_MAPPING,
    setOrder: (newOrder?: string[]) => {
      if (newOrder) {
        updateOrder(newOrder);
      }
    },
  });

  const isDataEmpty = !data || data.length === 0;

  if (isDataEmpty && !isPending) {
    return <TableEmptyState tableName="Filing History" />;
  }

  return (
    <KDataTable
      headers={TABLE_HEAD}
      numericCols={NUMERIC_COLUMNS}
      defaultVisibleColumns={TABLE_HEAD}
      sortHeaders={SORT_HEADERS}
      getSortIcon={getSortIcon}
      handleSort={header => {
        handleSort(header);
        return '';
      }}
    >
      {data.map(({ id, ...rest }: FilingInstance) => (
        <FilingHistoryTableRow key={id} id={id} {...rest} />
      ))}
      {isPending && <TableRowSkeleton length={25} columns={TABLE_HEAD} />}
    </KDataTable>
  );
};

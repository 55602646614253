import { Flex, Separator } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';

type VdaSuggestionModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onExploreVdaClick?: () => void;
  onStandardRegistrationClick?: () => void;
  isLoading?: boolean;
};

export const VdaSuggestionModal = ({
  isOpen,
  onClose,
  onExploreVdaClick,
  onStandardRegistrationClick,
  isLoading,
}: VdaSuggestionModalProps) => {
  return (
    <DialogRoot size={'md'} closeOnInteractOutside={false} open={isOpen}>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader pb={2}>
          <DialogTitle>Explore a Voluntary Disclosure Agreement (VDA)</DialogTitle>
        </DialogHeader>
        <DialogBody>
          For businesses like yours, our analysis recommends exploring a VDA in this jurisdiction to potentially reduce
          tax liabilities significantly compared to standard registration and backfiling. Select ‘Explore VDA’ to have
          our team contact you with further details.
        </DialogBody>
        <Separator />
        <DialogFooter>
          <Flex gap={4}>
            <Button
              variant={'outline'}
              borderColor={'secondary.500'}
              color={'secondary.500'}
              onClick={onStandardRegistrationClick}
            >
              Standard Registration
            </Button>
            <Button
              variant={'solid'}
              color={'white'}
              bg={'secondary.500'}
              onClick={onExploreVdaClick}
              loading={isLoading}
            >
              Explore VDA
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

import { CountryCodeEnum } from '_api-client';
import { Flex, Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { CAStatesByCode, USStatesByCode } from 'constants/app-constants';
import ExemptionBadge from 'pages/Exemptions/components/exemptionStateBadge';
import { toDateShort } from 'utils/dates';

import { ExemptionAction } from '../../../Exemptions/components/exemption-action';

export const ExemptionTable = ({ captions, data, isPending }: any) => {
  if (data.length === 0 && !isPending) {
    return (
      <TableEmptyState
        tableName="Exemptions"
        uniqueHelperTxt="Customer does not have any exemptions added"
        height="154px"
      />
    );
  }

  return (
    <KDataTable headers={captions}>
      {data?.map((row: any) => (
        <ExemptionTableRow
          key={row.id}
          id={row.id !== null ? row.id : ''}
          country_code={row.country_code !== null ? row.country_code : ''}
          jurisdiction={row.jurisdiction !== null ? row.jurisdiction : ''}
          start_date={row.start_date !== null ? row.start_date : ''}
          end_date={row.end_date !== null ? row.end_date : ''}
          FEIN={row.FEIN !== null ? row.FEIN : ''}
          sales_tax_id={row.sales_tax_id !== null ? row.sales_tax_id : ''}
          status={row.status !== null ? row.status : ''}
          exemption={row}
        />
      ))}
      {isPending && <TableRowSkeleton length={3} columns={captions} />}
    </KDataTable>
  );
};

const ExemptionTableRow = ({
  country_code,
  jurisdiction,
  start_date,
  end_date,
  FEIN,
  sales_tax_id,
  status,
  exemption,
}: any) => {
  const getStateLabel = (jurisdiction: string) => {
    if (exemption.country_code === CountryCodeEnum.US) {
      const state = USStatesByCode[jurisdiction];
      return state ? state.label : '';
    } else if (exemption.country_code === CountryCodeEnum.CA) {
      const state = CAStatesByCode[jurisdiction];
      return state ? state.label : '';
    } else {
      return jurisdiction;
    }
  };
  return (
    <Table.Row>
      <Table.Cell width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{country_code}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '100px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{getStateLabel(jurisdiction)}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(start_date)}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(end_date)}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{FEIN}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{sales_tax_id}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap" justifyContent={'space-between'}>
          <ExemptionBadge status={status} />
          <ExemptionAction exemption={exemption} />
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ExemptionsForm } from './exemptions-form';

export const CreateExemption = () => {
  const { open, onOpen, onClose } = useDisclosure();
  const { customerId = '' } = useParams<{ customerId: string }>();

  return (
    <>
      <Button variant={'solid'} colorPalette="blue" onClick={onOpen}>
        Add Exemption
      </Button>
      {open && <ExemptionsForm isOpen={open} onClose={onClose} customerId={customerId} />}
    </>
  );
};

import { Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { ExemptionsForm } from 'pages/Customers/components/exemptions-form';
import { MdMoreVert } from 'react-icons/md';

type TransactionDetailActionProps = {
  customerId: string;
  transactionId: string;
  transactionDate?: string;
};
export const TransactionDetailAction = ({
  customerId,
  transactionId,
  transactionDate,
}: TransactionDetailActionProps) => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <MenuRoot>
      <MenuTrigger aria-label="Options">
        <IconButton variant="plain">
          <Icon>
            <MdMoreVert />
          </Icon>
        </IconButton>
      </MenuTrigger>
      <MenuContent>
        <MenuItem value="exemption" onClick={onOpen}>
          Add Exemption
        </MenuItem>
        {open && (
          <ExemptionsForm
            isOpen={open}
            onClose={onClose}
            customerId={customerId}
            transactionId={transactionId}
            transactionDate={transactionDate}
          />
        )}
      </MenuContent>
    </MenuRoot>
  );
};

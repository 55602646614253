import { useAuthInfo, useLogoutFunction } from '@propelauth/react';
import { useHandleNotification } from 'hooks/useApiNotification';
import { setOrg, useOrg } from 'hooks/useOrg';
import { resetGloballyStoredValues } from 'hooks/useTrpcWithPropelAuth';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AppLoader from './app-loader';

const AppRedirector = (): React.ReactNode => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const forceLogout = useLogoutFunction();
  const { orgId: selectedOrdId } = useOrg();
  const { orgHelper, refreshAuthInfo } = useAuthInfo();
  const { handleFailNotification } = useHandleNotification();
  const orgId = searchParams.get('orgId');
  const redirectUrl = searchParams.get('redirectUrl');

  const handleLogout = () => {
    resetGloballyStoredValues();
    localStorage.clear();
    forceLogout(true);
  };

  const switchOrganization = async () => {
    try {
      const orgs = orgHelper?.getOrgs();
      if (!orgs) {
        console.error('[ORGANIZATION] orgHelper.getOrgs() returned undefined');
        handleLogout();
        return;
      }
      const organization = orgs.find(({ orgMetadata }) => orgMetadata.organization_id === orgId);
      if (!organization) {
        console.error('[ORGANIZATION] Organization not found:', orgId);
        handleLogout();
        return;
      }

      try {
        await refreshAuthInfo();
        setOrg({
          orgId: organization.orgMetadata.organization_id,
          externalId: organization.orgId,
          isTest: organization.orgMetadata.is_test ?? true,
          name: organization.orgName ?? organization.orgMetadata.organization_id,
        });

        navigate(redirectUrl ?? '/', { replace: true });
      } catch (tokenError) {
        // Handle specific token-related errors
        console.error('[SWITCH_ORG] Token error:', tokenError);
        handleFailNotification(tokenError);
      }
    } catch (error) {
      console.error('[SWITCH_ORG] General error:', error);
      handleFailNotification(error);
      handleLogout();
    }
  };

  useEffect(() => {
    if (!orgId || !redirectUrl) {
      navigate('/', { replace: true });
      return;
    }
    if (orgId === selectedOrdId) {
      navigate(redirectUrl, { replace: true });
      return;
    }
    switchOrganization();
  }, [orgId, redirectUrl]);

  return <AppLoader />;
};

export default AppRedirector;

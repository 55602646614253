import { Grid, HStack, Skeleton, Stack, Tabs } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, getConnections } from 'apis/connections';
import AppHeader from 'app/app-header';
import { useOrg } from 'hooks/useOrg';
import { cloneElement, Fragment } from 'react';
import { ConnectionStatusEnum, SourceEnum } from 'schema/types-schema.d';
import { ApiResponse, ConnectionInstance } from 'types/shared-types';

import { integrationTabs } from './tabs';

const Integrations = () => {
  const { orgId } = useOrg();
  const { data, isPending } = useQuery<
    ApiResponse<ConnectionInstance>,
    unknown,
    Record<SourceEnum, ConnectionStatusEnum>
  >({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getConnections(orgId);
      return data;
    },
    select: data => {
      const connectionMap = (data.items || []).reduce(
        (map, { source, status }) => {
          map[source] = status;
          return map;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );

      return Object.values(SourceEnum).reduce(
        (acc, curr) => {
          acc[curr] = connectionMap[curr] || ConnectionStatusEnum.INACTIVE;
          return acc;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );
    },
  });

  const tabHeaders = integrationTabs.map(tab => tab.title);

  if (isPending) {
    return <Skeleton height="full" width="full" />;
  }

  return (
    <Stack>
      <HStack justifyContent={'space-between'}>
        <AppHeader />
      </HStack>
      <Tabs.Root unmountOnExit colorPalette="blue" defaultValue={integrationTabs[0].title}>
        <Tabs.List>
          {tabHeaders.map((header, i) => {
            return (
              <Tabs.Trigger value={header} key={i} fontSize={'sm'}>
                {header}
              </Tabs.Trigger>
            );
          })}
        </Tabs.List>
        {integrationTabs.map(({ cards, title }) => (
          <Tabs.Content value={title} maxWidth={'1244px'} px={0} key={title}>
            <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} alignItems={'stretch'}>
              {cards.map(({ source, component }, i) => {
                const isConnected = data?.[source] === ConnectionStatusEnum.ACTIVE;
                return (
                  <Fragment key={`${title}-${i}`}>
                    {cloneElement(component, { ...component.props, isConnected })}
                  </Fragment>
                );
              })}
            </Grid>
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </Stack>
  );
};
export default Integrations;

import { Box, createListCollection, Flex, HStack, Input, Link, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REGISTRATION_STATE_KEY, updateRegistrationApi } from 'apis/registration-apis';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { NO_SALES_TAX_STATES } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { CountryCodeEnum, RegistrationInstance } from 'types/shared-types';
import { formatCanadianProvinceOptions } from 'utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  country_code: Yup.string(),
  state_code: Yup.string(),
  registration_date: Yup.date(),
  registration_email: Yup.string().email('Invalid email address'),
  filing_frequency: Yup.string(),
  filing_days: Yup.string(),
  username: Yup.string(),
  password_plain_text: Yup.string(),
  security_questions: Yup.array().notRequired(),
});

export const EditRegistrationForm = ({
  payload,
  onClose,
}: {
  payload: Partial<RegistrationInstance>;
  onClose: () => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { countries, getStates } = useLocationData();
  const [registrationStateOptions, setRegistrationStateOptions] = useState<LocationOptionType[]>([]);

  const [securityQuestions, setSecurityQuestions] = useState([{ question: '', answer: '' }]);
  console.log('state ===>', countries, registrationStateOptions);
  const mutation = useMutation({
    mutationFn: (edit: any) => {
      if (!payload.id || !orgId) {
        console.error('registrationId or orgId is undefined');
        return Promise.reject(new Error('id or orgId is undefined'));
      }
      return updateRegistrationApi(payload.id, orgId, edit);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      onCloseModal();
      handleSuccessNotification('Registration updated successfully.');
    },
    onError: (err: any) => {
      if (err && err.response?.status === 409) {
        toaster.create({
          title: 'Registration for state and country already exists',
          type: 'error',
        });

        return;
      }
      handleFailNotification(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      country_code: 'US',
      state_code: payload.state_code,
      state_name: payload.state_name,
      registration_date: payload.registration_date,
      registration_email: payload.registration_email,
      filing_frequency: payload.filing_frequency,
      filing_days: '',
      status: payload.status,
      username: payload.username,
      password_plain_text: '',
      password_metadata_plain_text: '',
      imported: true,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
      track('edits imported registration jurisdiction', { jurisdiction: values.state_name });
    },
  });
  const { track } = useTracking();

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(formik.values.country_code ?? '');
      if (formik.values.country_code === CountryCodeEnum.CA) {
        const canadaStatesOptions = formatCanadianProvinceOptions(states);
        setRegistrationStateOptions(canadaStatesOptions);
      } else {
        setRegistrationStateOptions(states);
      }
    };
    fetchStates();
  }, [getStates, formik.values.country_code]);

  const handleFilingChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const FilingOptions = [
      { value: 'MONTHLY', label: '20' },
      { value: 'QUARTERLY', label: '20' },
      { value: 'SEMI_ANNUALLY', label: '20' },
      { value: 'ANNUALLY', label: '20' },
    ];
    const selectedFilingFreq = event.target.value;
    formik.setFieldValue('filing_frequency', selectedFilingFreq);
    const selectedFiling = FilingOptions.find(filing => filing.value === selectedFilingFreq);

    if (selectedFiling) {
      formik.setFieldValue('filing_days', selectedFiling.label);
    } else {
      formik.setFieldValue('filing_days', '');
    }
  };

  const handleAddQuestion = () => {
    if (securityQuestions.length < 3) {
      setSecurityQuestions([...securityQuestions, { question: '', answer: '' }]);
    }
  };

  const handleQuestionChange = (index: number, field: 'question' | 'answer', value: string) => {
    const updatedQuestions = [...securityQuestions];
    updatedQuestions[index][field] = value;
    setSecurityQuestions(updatedQuestions);
    formik.setFieldValue('password_metadata_plain_text', JSON.stringify(updatedQuestions));
  };

  const onCloseModal = () => {
    formik.resetForm();
    setSecurityQuestions([{ question: '', answer: '' }]);
    onClose();
  };

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);

  const statesCollection = useMemo(() => {
    return createListCollection({
      items: registrationStateOptions,
      isItemDisabled: ({ value }) => NO_SALES_TAX_STATES.includes(value),
    });
  }, [registrationStateOptions]);

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      size={'md'}
      open={Boolean(payload)}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>Edit Registration</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Stack>
            <HStack align={'baseline'}>
              <Field label="Country">
                <SelectRoot
                  collection={countriesCollection}
                  value={formik.values.country_code ? [formik.values.country_code] : undefined}
                  onValueChange={({ value }) => {
                    formik.setFieldValue('country_code', value[0]);
                    formik.setFieldValue('state_code', '');
                  }}
                >
                  <SelectTrigger>
                    <SelectValueText />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {countriesCollection.items.map(item => (
                      <SelectItem key={item.value} item={item}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
              <Field
                label="State"
                invalid={!!(formik.errors.state_code && formik.touched.state_code)}
                errorText={formik?.errors?.state_code?.toString()}
                required
              >
                <SelectRoot
                  collection={statesCollection}
                  value={formik.values.state_code ? [formik.values.state_code] : undefined}
                  onValueChange={({ value }) => {
                    const item = statesCollection.items.find(({ value: itemValue }) => itemValue === value[0]);
                    if (item) {
                      formik.setFieldValue('state_name', item.label);
                      formik.setFieldValue('state_code', item.value);
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValueText />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {statesCollection.items.map(item => (
                      <Tooltip
                        key={item.value}
                        content={`Sales tax registration isn’t needed in ${item.label} because there’s no sales tax in this state.`}
                        openDelay={100}
                        showArrow
                        positioning={{ placement: 'bottom', offset: { mainAxis: 0, crossAxis: 5 } }}
                        disabled={!NO_SALES_TAX_STATES.includes(item.value)}
                      >
                        <Box cursor="not-allowed">
                          <SelectItem item={item}>
                            <Text>{item.label}</Text>
                          </SelectItem>
                        </Box>
                      </Tooltip>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field
                label="Registration Date"
                invalid={!!(formik.errors.registration_date && formik.touched.registration_date)}
                errorText={formik.errors.registration_date}
                required
              >
                <DatePicker
                  selected={formik.values.registration_date ? formik.values.registration_date : ''}
                  onChange={date => {
                    formik.setFieldValue('registration_date', date);
                  }}
                />
              </Field>
              <Field
                label="Registration Email"
                invalid={!!(formik.errors.registration_email && formik.touched.registration_email)}
                errorText={formik.errors.registration_email}
                required
              >
                <Input
                  id="registration_email"
                  type="email"
                  name="registration_email"
                  value={formik.values.registration_email ? formik.values.registration_email : ''}
                  onChange={formik.handleChange}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            <Field
              label="Filing Frequency"
              invalid={!!(formik.errors.filing_frequency && formik.touched.filing_frequency)}
              errorText={formik.errors.filing_frequency}
              required
            >
              <NativeSelectRoot>
                <NativeSelectField
                  id="filing_frequency"
                  name="filing_frequency"
                  value={formik.values.filing_frequency}
                  onChange={handleFilingChange}
                >
                  <option>Select filing frequency</option>
                  <option value="MONTHLY">Monthly</option>
                  <option value="QUARTERLY">Quarterly</option>
                  <option value="SEMI_ANNUALLY">Semi-annually</option>
                  <option value="ANNUALLY">Annually</option>
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field
                label={
                  <HStack gap="0.5">
                    <Text>Username</Text>

                    <Tooltip
                      content={`The username that is used to login this jurisdictions website, this could be your email address or a username.`}
                    >
                      <MdInfo />
                    </Tooltip>
                  </HStack>
                }
                invalid={!!formik.errors.username}
                errorText={formik.errors.username}
                required
              >
                <Input id="username" name="username" value={formik.values.username} onChange={formik.handleChange} />
              </Field>
              <Field
                label={
                  <HStack gap="0.5">
                    <Text>Password</Text>

                    <Tooltip content={`The password you use to log into this jurisdiction's website.`}>
                      <MdInfo />
                    </Tooltip>
                  </HStack>
                }
                invalid={!!formik.errors.password_plain_text}
                errorText={formik.errors.password_plain_text}
                required
              >
                <Input
                  id="password_plain_text"
                  name="password_plain_text"
                  value={formik.values.password_plain_text}
                  onChange={formik.handleChange}
                />
              </Field>
            </HStack>
            {securityQuestions.map((question, index) => (
              <Box key={index} mt={4}>
                <Field key={`question_${index + 1}`} label={`Question ${index + 1}`}>
                  <Input
                    placeholder="Question"
                    id={`question_${index + 1}`}
                    value={question.question}
                    onChange={e => handleQuestionChange(index, 'question', e.target.value)}
                  />
                </Field>
                <Field key={`answer_${index + 1}`} mt={2}>
                  <Input
                    placeholder="Answer"
                    value={question.answer}
                    onChange={e => handleQuestionChange(index, 'answer', e.target.value)}
                  />
                </Field>
              </Box>
            ))}
            {securityQuestions.length < 3 && (
              <Link paddingY={'16px'} fontWeight={500} color={'#4285F4'} onClick={handleAddQuestion}>
                + Another Question
              </Link>
            )}
          </Stack>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              loading={mutation.isPending}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

import { Flex, Table, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { RegistrationStatus } from 'types/shared-types';

import RegistrationStateBadge from './registration-state-badge';

const captions = ['Country', 'State', 'Registration Date', 'Registration Email', 'Filing Frequency', 'Status'];

const RegistrationsOnboarding = () => {
  return (
    <Table.Root>
      <Table.Header>
        <Table.Row my=".8rem" pl="0px">
          {captions.map((caption: string, idx: number) => {
            return <Table.ColumnHeader key={idx}>{caption}</Table.ColumnHeader>;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={{ sm: '100px' }} pl="25px">
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <Text>United States</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '250px' }} pl="20px">
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <Text>Massachusetts</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '200px' }}>
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <Text>Dec 13, 2023</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '200px' }}>
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <Text>helloceo@kraft8bubbles.com</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '180px' }}>
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <Text>Monthly</Text>
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '180px' }} pl="0px">
            <Flex alignContent={'center'} minWidth="100%" flexWrap="nowrap">
              <RegistrationStateBadge status={RegistrationStatus.UNREGISTERED} />
            </Flex>
          </Table.Cell>
          <Table.Cell width={{ sm: '180px' }}>
            <Flex align={'center'} minWidth="100%" flexWrap="nowrap">
              <Button variant={'outline'} type="submit">
                Register
              </Button>
            </Flex>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};

export default RegistrationsOnboarding;

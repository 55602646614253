import { defineRecipe } from '@chakra-ui/react';

export const badgeRecipe = defineRecipe({
  base: {
    borderRadius: 'l1',
    justifyContent: 'center',
    fontWeight: '400',
  },
  variants: {
    colorPalette: {
      red: {
        bg: 'red.50',
        color: 'red.900',
      },
      gray: {
        bg: '#F7F7FB',
        color: 'gray.700',
      },
      lightYellow: {
        bg: 'primary.50',
        color: 'orange.900',
      },
      green: {
        bg: 'green.50',
        color: 'green.900',
      },
      blue: {
        bg: 'secondary.50',
        color: 'secondary.500',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  } as any, // TODO: we need to wait for chakra-ui to fix typings here
});

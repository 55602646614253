import { Button, Center, Container, createListCollection, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { handleApiErrorMessage } from 'apis/api';
import { getOrganizations } from 'apis/organizations-apis';
import { KintsugiLogo } from 'components/icons';
import { Radio, RadioGroup } from 'components/ui/radio';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';
import { getTokenIsTooLong } from 'hooks/useTrpcWithPropelAuth';
import React, { useMemo, useState } from 'react';
import { generateState, prepareStripInstallLink } from 'utils/utils';

enum Mode {
  LIVE = 'live',
  TEST = 'test',
}
const STRIPE_LIVE_INSTALL_LINK = import.meta.env.VITE_STRIPE_LIVE_INSTALL_LINK;
const STRIPE_TEST_INSTALL_LINK = import.meta.env.VITE_STRIPE_TEST_INSTALL_LINK;

const StripeAppInstallWithOrgSelector = () => {
  const { orgId } = useOrg();
  const { page, size, query } = useTableFilters({ size: 100 });
  const { data, isPending } = useQuery({
    queryKey: ['orgs', page, size, query],
    queryFn: async () => {
      const { data } = await getOrganizations(orgId, { page, size, query });
      return data;
    },
  });
  const [selectedMode, setSelectedMode] = useState<Mode | null>(null);
  const [selectedOrgId, setSelectedOrgId] = useState<string>();

  const tokenIsTooLong = getTokenIsTooLong();

  const orgCollections = useMemo(() => {
    return createListCollection({
      items: data?.items || [],
      itemToString: ({ name }) => name || '',
      itemToValue: ({ external_id }) => external_id,
    });
  }, [data]);

  const handleInstall = () => {
    const state = generateState();
    if (selectedMode && selectedOrgId) {
      const preparedLink = prepareStripInstallLink({
        link: selectedMode === Mode.LIVE ? STRIPE_LIVE_INSTALL_LINK : STRIPE_TEST_INSTALL_LINK,
        selectedOrgId,
        mode: selectedMode,
        state: state,
      });
      window.location.href = preparedLink;
    } else {
      handleApiErrorMessage({
        title: 'No Mode or Org Id selected',
        message: 'There is no mode or organization id selected!',
      });
    }
  };

  return (
    <Container maxW="container.sm" py={8}>
      <VStack gap={6} align="stretch">
        <Center>
          <Icon w="100px" h="100px">
            <KintsugiLogo />
          </Icon>
        </Center>
        <Text textAlign="center" fontSize="lg">
          Sales tax automation, nexus tracking, registrations, filings, and tax calculator
        </Text>
        <Center>
          <RadioGroup
            disabled={tokenIsTooLong}
            value={selectedMode ?? ''}
            onValueChange={({ value }) => setSelectedMode(value as Mode)}
          >
            <VStack gap={4} align="start">
              <Radio value={Mode.LIVE}>LIVE</Radio>
              <Radio value={Mode.TEST}>TEST</Radio>
            </VStack>
          </RadioGroup>
        </Center>
        <Center>
          <Flex maxWidth="200px" justify="center">
            <SelectRoot
              disabled={tokenIsTooLong || isPending}
              minW="200px"
              collection={orgCollections}
              value={selectedOrgId ? [selectedOrgId] : undefined}
              onValueChange={({ value }) => setSelectedOrgId(value[0])}
            >
              <SelectTrigger>
                <SelectValueText />
              </SelectTrigger>
              <SelectContent>
                {orgCollections.items.map(item => (
                  <SelectItem key={item.external_id} item={item}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Flex>
        </Center>
        {tokenIsTooLong && (
          <Center>
            <Text color="red">You are member to too many organizations</Text>
          </Center>
        )}

        <Center>
          <Button
            w="10rem"
            colorScheme="blue"
            onClick={handleInstall}
            disabled={!selectedMode || !selectedOrgId || tokenIsTooLong}
          >
            Install Stripe App
          </Button>
        </Center>
      </VStack>
    </Container>
  );
};

export default StripeAppInstallWithOrgSelector;

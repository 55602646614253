import { Card, Flex, Text } from '@chakra-ui/react';
import { withAuthInfo } from '@propelauth/react';
import { createShopifyConnection } from 'apis/connctions-api';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ConnectShopify = withAuthInfo(() => {
  const navigate = useNavigate();
  const { orgId } = useOrg();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const shopURL = urlParams.get('shopURL');
  const shopId = shopURL?.split('.')[0] || '';

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const createConnectionForShopify = async (shopId: string, shopURL: string, orgId: string) => {
      try {
        setIsLoading(true);
        const response = await createShopifyConnection(shopId, shopURL, orgId);
        console.log(`response: ${response}`);
        setTimeout(() => {
          navigate('/source');
        }, 2000);
      } catch (error) {
        console.error('Error creating connection:', error);
        setError('Error creating connection. Please contact support at help@trykintsugi.com.');
      } finally {
        setIsLoading(false);
      }
    };

    if (shopURL && shopId && orgId) {
      createConnectionForShopify(shopId, shopURL, orgId);
    }
  }, [shopId, shopURL]);

  if (isLoading) {
    return (
      <Card.Root title="Shopify" h="100%">
        <Text>Connecting...</Text>
      </Card.Root>
    );
  }

  if (!shopId || !shopURL) {
    return (
      <Flex align="center" justify="center" direction="column" h="100%" w="100%" p="2rem">
        <Text fontSize="md">
          <strong>Shop ID</strong> and <strong>Shop URL</strong> can&apos;t be empty.
        </Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Card.Root title="Shopify" h="100%" p={4}>
        <Text>{error}</Text>
      </Card.Root>
    );
  }

  return (
    <Card.Root title="Shopify" h="100%">
      <Text fontSize="1rem" fontWeight={400} p={4}>
        You&apos;re all set! Redirecting to Integrations...
      </Text>
    </Card.Root>
  );
});

export default ConnectShopify;

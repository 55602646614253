import { Badge, Flex, Table, Text } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useNavigate } from 'react-router-dom';
import { Transaction, TransactionStatus } from 'types/transactions';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

const TABLE_HEAD = ['ID', 'CUSTOMER NAME', 'DESCRIPTION', 'DATE', 'STATE', 'SALES', 'CALCULATED TAX', 'STATUS'];

const NUMERIC_COLUMNS = ['SALES', 'CALCULATED TAX'];

type RelatedTransactionTableProps = {
  data: Transaction[];
};

export const RelatedTransactionTable = ({ data }: RelatedTransactionTableProps) => {
  const isDataEmpty = !data || data.length === 0;

  return (
    <>
      {isDataEmpty ? (
        ''
      ) : (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              {TABLE_HEAD.map((caption, idx) => (
                <Table.Cell key={idx} textAlign={NUMERIC_COLUMNS.includes(caption) ? 'end' : 'start'}>
                  <Flex
                    alignItems="center"
                    justifyContent={NUMERIC_COLUMNS.includes(caption) ? 'flex-end' : 'flex-start'}
                    gap={1}
                  >
                    {caption}
                  </Flex>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ id, addresses, ...rest }: Transaction) => (
              <RelatedTransactionTableRow key={id} id={id} addresses={addresses} {...rest} />
            ))}
          </Table.Body>
        </Table.Root>
      )}
    </>
  );
};

const RelatedTransactionTableRow = ({
  id,
  external_id,
  date,
  total_amount,
  total_tax_amount_calculated,
  description,
  status,
  customer,
  state,
}: Transaction) => {
  const dateObject = new Date(date);
  const formattedDate = toDateShort(dateObject);
  const statusVariant = transactionStatusVariantMap[status as TransactionStatus];
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };

  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      <Table.Cell width={{ sm: '100px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '163px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{customer.name}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '323px' }} maxW="20rem">
        <Tooltip content={description}>
          <Flex alignItems="center" flexWrap="nowrap">
            <Text lineClamp={1} maxWidth={'100%'}>
              {description}
            </Text>
          </Flex>
        </Tooltip>
      </Table.Cell>
      <Table.Cell width={{ sm: '120px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{formattedDate}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '60px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{state}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '163px' }} textAlign="end">
        <Text>{formatCurrency(total_amount)}</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '166px' }} textAlign="end">
        <Text>{formatCurrency(total_tax_amount_calculated)}</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '125px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          {statusVariant && <Badge colorPalette={statusVariant.variant}>{statusVariant.title}</Badge>}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

import { Badge, Box, Heading, HStack, IconButton, List, Stack, Text } from '@chakra-ui/react';
import { ArrowLeft, CurrencyConvertedIcon, MarketplaceIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import { transactionStatusVariantMap } from 'constants/transactions';
import { Link, useNavigate } from 'react-router-dom';
import { TransactionInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';

type TransactionDetailsHeaderLabelProps = {
  data?: TransactionInstance;
  isPending: boolean;
  isError: boolean;
  onClick?: () => void;
};
export const TransactionDetailsHeaderLabel = ({
  data: transactionDetails,
  isError,
  isPending,
  onClick,
}: TransactionDetailsHeaderLabelProps) => {
  const navigate = useNavigate();

  if (isPending || !transactionDetails) {
    return null;
  }

  if (isError) {
    console.log('Error fetching transaction details');
    return null;
  }

  const status = transactionDetails.status;
  const { external_id, marketplace, date, customer_id, type } = transactionDetails;
  const statusVariant = transactionStatusVariantMap[status];

  const handleOnclick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate('/transactions');
    }
  };

  if (!statusVariant?.variant) {
    return null;
  }

  const showCurrencyConversionTooltip =
    transactionDetails?.destination_currency &&
    transactionDetails?.destination_currency !== transactionDetails?.currency;

  const getCurrencyConvertedTooltip = () => {
    return (
      <Stack gap={0}>
        <Text color={'white'}>The transaction amounts are converted to the tax jurisdiction&apos;s currency.</Text>
        <List.Root ml={2}>
          <List.Item _marker={{ color: 'white' }}>Currency: {transactionDetails?.currency}</List.Item>
          <List.Item _marker={{ color: 'white' }}>
            Converted Currency: {transactionDetails?.destination_currency}
          </List.Item>
          <List.Item _marker={{ color: 'white' }}>
            Currency Conversion Rate: 1 {transactionDetails?.currency} = {transactionDetails?.conversion_rate}{' '}
            {transactionDetails?.destination_currency}
          </List.Item>
        </List.Root>
      </Stack>
    );
  };

  return (
    <HStack alignItems={'center'} mt={'16px'}>
      <Stack gap={1}>
        <HStack alignContent={'center'}>
          <IconButton aria-label="back" variant={'ghost'} rounded={4} onClick={handleOnclick}>
            <ArrowLeft />
          </IconButton>
          <Heading as={'h1'} fontSize={'20px'}>
            Transaction Details
          </Heading>
          {type !== 'SALE' && <Badge colorPalette={'blue'}>Credit Note</Badge>}
          <Badge colorPalette={statusVariant.variant}>{statusVariant.title}</Badge>
          {showCurrencyConversionTooltip && (
            <Tooltip
              content={getCurrencyConvertedTooltip()}
              contentProps={{
                width: 'auto',
                maxWidth: '490px',
                minWidth: 'auto',
              }}
            >
              <Box cursor="pointer" bgColor="blue.50" px="1">
                <CurrencyConvertedIcon color={'blue.500'} height="16px" />
              </Box>
            </Tooltip>
          )}
          {marketplace && (
            <Tooltip
              content={`A Marketplace transactions is a transaction where the tax is paid by the marketplace directly`}
            >
              <Box px="1" cursor="pointer" bgColor="blue.500">
                <MarketplaceIcon size="sm" cursor={'pointer'} />
              </Box>
            </Tooltip>
          )}
        </HStack>
        <HStack direction={'row'} alignItems={'center'} ml={'50px'}>
          <Text fontSize={'sm'} color={'gray.500'}>
            ID: {external_id}
          </Text>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Link to={`/customers/${customer_id}`}>
            <Text fontSize={'sm'} color={'gray.500'} textDecoration={'underline'} textUnderlineOffset={2}>
              {customer_id}
            </Text>
          </Link>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Text fontSize={'sm'} color={'gray.500'}>
            {toDateShort(new Date(date))}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};

import { Flex, Skeleton, useDisclosure, VStack } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FILINGS_BY_ID_STATE_KEY, FILINGS_STATE_KEY, getFilingForId, updateFilingStatus } from 'apis/filing-apis';
import {
  ORGANIZATION_SERVICE_QUERY_KEYS,
  putAutoFileAutoRegister,
  useAutoFileAutoRegisterQuery,
} from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import React, { useEffect } from 'react';
import { AutoFileAutoRegisterUpdateRequest, FilingDetailsInstance, FilingStatus } from 'types/shared-types';

import FilingDetails from './filing-details';

const FilingRegistration: React.FC = () => {
  const { open, onOpen, onClose: closeModal } = useDisclosure();
  const { orgId, isTest } = useOrg();
  const queryClient = useQueryClient();
  const [filingId, setSearchParams] = useQueryParam('filingId');
  const [editFiling] = useQueryParam('editFiling');
  const { handleSuccessNotification } = useHandleNotification();

  const onClose = () => {
    setSearchParams({});
    closeModal();
  };

  const { isLoading, data, refetch } = useQuery({
    enabled: false,
    queryKey: [FILINGS_BY_ID_STATE_KEY, filingId],
    queryFn: async () => {
      const res = await getFilingForId(orgId, filingId);
      return res?.data || {};
    },
  });

  useEffect(() => {
    if (!open && editFiling && filingId) {
      onOpen();
      refetch();
    }
  }, [editFiling]);

  const { id, start_date, end_date, can_file, country_code } = (data || {}) as FilingDetailsInstance;

  const mutation = useMutation({
    mutationFn: () => {
      return updateFilingStatus(orgId, id || '', {
        start_date,
        end_date,
        status: FilingStatus.FILING,
        country_code,
      });
    },
    onSuccess: async () => {
      try {
        await Promise.all([
          queryClient.refetchQueries({ queryKey: [FILINGS_STATE_KEY] }),
          queryClient.invalidateQueries({ queryKey: [FILINGS_BY_ID_STATE_KEY] }),
        ]);
      } catch (error) {
        console.error('Failed to invalidate queries:', error);
      }
      if (autoFileformik.isValid && autoFileformik.values.auto_file) {
        await autoFileformik.submitForm();
      }
      onClose();
      handleSuccessNotification('Filing updated successfully.');
    },
  });

  const { isLoading: isOrgDetailsLoading, data: orgDetails } = useAutoFileAutoRegisterQuery(orgId, { enabled: isTest });

  const autoFileMutation = useMutation({
    mutationFn: async (payload: AutoFileAutoRegisterUpdateRequest) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.autoFileAutoRegister(orgId)] });
      queryClient.invalidateQueries({ queryKey: [FILINGS_STATE_KEY] });
    },
  });

  const autoFileformik = useFormik({
    initialValues: {
      auto_file: orgDetails?.auto_file || false,
      auto_register: orgDetails?.auto_register || false,
    },
    onSubmit: async values => {
      await autoFileMutation.mutateAsync(values);
    },
    enableReinitialize: true,
  });

  return (
    <DialogRoot scrollBehavior="inside" closeOnInteractOutside={false} size={'sm'} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Filing Details</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger onClick={onClose} />
        <DialogBody>
          {isLoading || isOrgDetailsLoading ? (
            <VStack gap={4}>
              {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} height="30px" width="full" />
              ))}
            </VStack>
          ) : (
            <FilingDetails
              data={data}
              shouldShowSubheader={true}
              formik={autoFileformik}
              shouldShowAutoFile={!orgDetails?.auto_file}
            />
          )}
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={mutation.isPending}
              disabled={!can_file}
              variant={'solid'}
              colorPalette="blue"
              width={'90px'}
              onClick={() => mutation.mutate()}
            >
              File
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default FilingRegistration;

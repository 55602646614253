import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { StripeIcon } from './icons/stripe';

export default function StripeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const navigate = useNavigate();
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const handleOpenStripeModal = () => {
    setIsBusinessAddressPromptOpen(false);
    navigate('/stripe-app');
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Stripe'}
        description={'Financial Infrastructure for the Internet'}
        icon={<StripeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : navigate('/stripe-app'))}
        btnProps={{ colorPalette: 'gray' }}
        installationGuide="https://intercom.help/kintsugi/en/articles/9939495-integration-stripe"
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleOpenStripeModal}
      />
    </>
  );
}

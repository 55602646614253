import { HStack, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { BILLING_STATE_KEY, getOrgBillingDetails } from 'apis/billing-apis';
import { getRegistrations, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import AppHeader from 'app/app-header';
import { RegistrationTrackingToolbar } from 'app-header-toolbar/registration-tracking-toolbar';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import useOnboarding from 'hooks/useOnboarding';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { RegistrationInstance, RegistrationStatus } from 'types/shared-types';

import RegistrationsOnboarding from './components/registration-onboarding';
import { RegistrationsTable } from './components/table';

const Registrations = () => {
  const { orgId } = useOrg();
  const { page, size, setFilters } = useTableFilters();
  const { isOnboardingInprogress } = useOnboarding();

  const { data: billingDetails, isPending: isBillingPlanPending } = useQuery({
    queryKey: [BILLING_STATE_KEY, 'plan', orgId],
    queryFn: async () => {
      return await getOrgBillingDetails(orgId);
    },
    refetchOnWindowFocus: false,
  });

  const { isPending, data } = useQuery({
    queryKey: [REGISTRATION_STATE_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getRegistrations(orgId, { page, size });
      return data;
    },
    select: data => {
      return {
        items: data?.items.map((item: RegistrationInstance) => ({
          ...item,
          billingPlan: billingDetails ? billingDetails.billing_plan : BillingPlanEnum.FREE,
        })),
        pages: data?.pages,
        total: data?.total,
      };
    },
  });
  const registrationsData = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = registrationsData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE;

  const hasAwaitingClarification = registrationsData?.some(
    (registration: RegistrationInstance) => registration.status === RegistrationStatus.AWAITING_CLARIFICATION
  );

  if (isOnboardingInprogress()) {
    return <RegistrationsOnboarding />;
  }

  const allPending = isBillingPlanPending || isPending;

  return (
    <Stack>
      <HStack gap={1} justifyContent="space-between">
        <AppHeader />
        <RegistrationTrackingToolbar />
      </HStack>
      <RegistrationsTable
        data={registrationsData}
        hasAwaitingClarification={hasAwaitingClarification}
        isPending={allPending}
      />

      {isPaginationEnable && !allPending && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}
    </Stack>
  );
};

export default Registrations;

import { CountryCodeEnum } from '_api-client';
import { createListCollection, Flex, HStack, IconButton, Input, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { createExemption, uploadResellerCertificate } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import { TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import { RemoveIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Switch } from 'components/ui/switch';
import { toaster } from 'components/ui/toaster';
import { addYears, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useStateOptions } from 'hooks/useStateOptions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getFormattedDate } from 'utils/dates';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  country_code: string().required('Country is required'),
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  jurisdiction: string().required('Jurisdiction is required'),
  FEIN: string().required('FEIN is required'),
  sales_tax_id: string().required('Sales Tax Id is required'),
});

type ExemptionsFormProps = {
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
  transactionId?: string;
  transactionDate?: string;
};

export const ExemptionsForm = ({
  isOpen,
  onClose,
  customerId,
  transactionId,
  transactionDate,
}: ExemptionsFormProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [fileName, setFileName] = useState('');
  const { countries } = useLocationData();
  const { stateOptions, fetchStateOptions } = useStateOptions({ formatter: 'ca_exemption' });
  const [country, setCountry] = useState<CountryCodeEnum>(CountryCodeEnum.US);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const uploadResellerCertificateMutate = useMutation({
    mutationFn: (payload: any) => {
      return uploadResellerCertificate(payload.id, orgId, payload.formData);
    },
    onSuccess: () => {
      queryInvalidate();
      handleSuccessNotification('Exemption added successfully');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return createExemption(orgId, payload);
    },
    onSuccess: data => {
      const file = formik.values.certificate_location;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadResellerCertificateMutate.mutate({ id: data.id, formData });
      } else {
        queryInvalidate();
        handleSuccessNotification('Exemption added successfully');
      }
      onClose();
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const queryInvalidate = () => {
    queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId, 'exemptions'] });
    queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY, transactionId] });
    queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY] });
  };

  const calculateEndDate = (startDate: string) => {
    const endDate = addYears(parseISO(startDate), 3);
    return getFormattedDate(endDate, 'yyyy-MM-dd');
  };

  const handleStartDateChange = (date: string) => {
    const endDate: string = calculateEndDate(date);
    formik.setFieldValue('start_date', date);
    formik.setFieldValue('end_date', endDate);
  };
  console.log('state ==>', countries, setCountry, stateOptions, handleStartDateChange);
  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);
  const statesCollection = useMemo(() => {
    return createListCollection({
      items: stateOptions,
    });
  }, [stateOptions]);
  const currentDate = transactionDate
    ? getFormattedDate(transactionDate, 'yyyy-MM-dd')
    : getFormattedDate(new Date(), 'yyyy-MM-dd');
  const endDate = calculateEndDate(currentDate);

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile?.type !== 'application/pdf') {
      toaster.create({
        type: 'error',
        title: 'Invalid file type',
        description: 'Only PDF file is allowed.',
      });
      return;
    }
    setFileName(selectedFile?.name);
    formik.setFieldValue('certificate_location', selectedFile);
  };

  const determineExemptionType = (isReseller: boolean, transactionId?: string) => {
    if (isReseller) {
      return 'wholesale';
    }
    return transactionId ? 'transaction' : 'customer';
  };

  const formik = useFormik({
    initialValues: {
      country_code: CountryCodeEnum.US,
      exemption_type: determineExemptionType(false, transactionId),
      customer_id: customerId,
      start_date: currentDate,
      end_date: endDate,
      FEIN: '',
      sales_tax_id: '',
      jurisdiction: '',
      certificate_location: '',
      reseller: false,
      status: 'ACTIVE',
      transaction_id: transactionId,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  useEffect(() => {
    fetchStateOptions(country);
  }, [country, fetchStateOptions]);

  return (
    <DialogRoot
      size={'lg'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent colorPalette="blue" ref={contentRef} width={'500px'}>
        <DialogHeader>
          <DialogTitle>Add Exemption</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form noValidate>
            <Stack gap={'16px'}>
              <HStack align={'baseline'}>
                <Field
                  label="Country"
                  invalid={!!(formik.errors.country_code && formik.touched.country_code)}
                  errorText={formik.errors.country_code}
                  required
                >
                  <SelectRoot
                    collection={countriesCollection}
                    value={country ? [country] : undefined}
                    onValueChange={({ value }) => {
                      setCountry(value[0] as CountryCodeEnum);
                      formik.setFieldValue('country_code', value[0]);
                      formik.setFieldValue('jurisdiction', '');
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {countriesCollection.items.map(item => (
                        <SelectItem item={item} key={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
                <Field
                  label="State"
                  invalid={!!(formik.errors.jurisdiction && formik.touched.jurisdiction)}
                  errorText={formik.errors.jurisdiction}
                  required
                >
                  <SelectRoot
                    collection={statesCollection}
                    value={formik.values.jurisdiction ? [formik.values.jurisdiction] : undefined}
                    onValueChange={({ value }) => {
                      formik.setFieldValue('jurisdiction', value[0]);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {statesCollection.items.map(item => (
                        <SelectItem item={item} key={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </HStack>

              <HStack align={'baseline'}>
                <Field
                  label="Start Date"
                  invalid={!!(formik.errors.start_date && formik.touched.start_date)}
                  errorText={formik.errors.start_date}
                  required
                >
                  <DatePicker
                    maxDate={transactionDate ? new Date(transactionDate) : undefined}
                    selected={formik.values.start_date}
                    onChange={handleStartDateChange}
                  />
                </Field>
                <Field
                  label="End Date"
                  invalid={!!(formik.errors.end_date && formik.touched.end_date)}
                  errorText={formik.errors.end_date}
                  required
                >
                  <DatePicker
                    minDate={transactionDate ? new Date(transactionDate) : undefined}
                    selected={formik.values.end_date}
                    onChange={date => formik.setFieldValue('end_date', date)}
                  />
                </Field>
              </HStack>
              <HStack align={'baseline'}>
                <Field
                  label="FEIN"
                  invalid={!!(formik.errors.FEIN && formik.touched.FEIN)}
                  errorText={formik.errors.FEIN}
                  required
                >
                  <Input
                    id="FEIN"
                    type="string"
                    name="FEIN"
                    value={formik.values.FEIN}
                    onChange={formik.handleChange}
                  />
                </Field>
                <Field
                  label="Sales Tax ID"
                  invalid={!!(formik.errors.sales_tax_id && formik.touched.sales_tax_id)}
                  errorText={formik.errors.sales_tax_id}
                  required
                >
                  <Input
                    id="sales_tax_id"
                    type="string"
                    name="sales_tax_id"
                    value={formik.values.sales_tax_id}
                    onChange={formik.handleChange}
                  />
                </Field>
              </HStack>
              <Field>
                <Switch
                  id="reseller"
                  name="reseller"
                  value={formik.values.reseller ? 'true' : 'false'}
                  checked={formik.values.reseller}
                  onCheckedChange={e => {
                    formik.setFieldValue('reseller', e.checked);
                    const exemptionType = determineExemptionType(e.checked, transactionId);
                    formik.setFieldValue('exemption_type', exemptionType);
                  }}
                >
                  Is this customer a reseller?
                </Switch>
              </Field>
              {formik.values.certificate_location ? (
                <Stack p={0} m={0}>
                  <Text fontWeight={500}>Upload Certificate</Text>
                  <HStack>
                    <Text>{fileName}</Text>
                    <IconButton
                      aria-label="Remove file"
                      onClick={() => formik.setFieldValue('certificate_location', null)}
                      variant={'transparent-with-icon'}
                      pt={'10px'}
                      minWidth={'5px'}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </HStack>
                </Stack>
              ) : (
                <Flex
                  onDrop={formik.values.reseller ? handleFileDrop : undefined}
                  onDragOver={formik.values.reseller ? handleDragOver : undefined}
                  height="78px"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="1px dashed #4285F4"
                  borderRadius="2px"
                  p={'16px'}
                >
                  <Text>
                    Drag and Drop your files here or{' '}
                    <Text as="label" color={'#4285F4'} textDecoration={'underline'} cursor="pointer">
                      Choose File
                      <Input
                        type="file"
                        id="file"
                        accept=".pdf"
                        display="none"
                        onChange={e => {
                          if (e.target.files && e.target.files.length > 0) {
                            handleFileSelection(e.target.files[0]);
                          } else {
                            toaster.create({
                              type: 'error',
                              title: 'Error',
                              description: 'Error selecting file ',
                            });
                          }
                        }}
                        onClick={(e: any) => {
                          e.target.value = '';
                        }}
                      />
                    </Text>
                  </Text>
                  <Text fontSize={'12px'} color={'#5F647B'}>
                    Max file size: 10MB. Supported type: PDF
                  </Text>
                </Flex>
              )}
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => formik.handleSubmit()}
              loading={mutation.isPending || uploadResellerCertificateMutate.isPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

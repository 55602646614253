import { Badge, Flex, Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Transaction } from 'types/transactions';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

const statusBadges = {
  COMMITTED: <Badge colorPalette={'green'}>Committed</Badge>,
  VOIDED: <Badge colorPalette={'red'}>Voided</Badge>,
  CANCELLED: <Badge colorPalette={'red'}>Cancelled</Badge>,
  PENDING: <Badge colorPalette={'gray'}>Pending</Badge>,
} as Record<string, React.ReactNode>;

export const TransactionTable = ({ captions, data, isPending }: any) => {
  return (
    <KDataTable headers={captions} numericCols={['AMOUNT', 'TAX AMOUNT']}>
      {data?.map(({ id, addresses, ...rest }: Transaction) => (
        <TransactionTableRow key={id} id={id} addresses={addresses} {...rest} />
      ))}
      {isPending && <TableRowSkeleton columns={captions} />}
    </KDataTable>
  );
};

const TransactionTableRow = ({
  id,
  external_id,
  description,
  date,
  state,
  total_amount,
  total_tax_amount_calculated,
  status,
}: any) => {
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };

  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{description}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(date)}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{state}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '80px' }} textAlign="end">
        <Text>{formatCurrency(total_amount)}</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '80px' }} textAlign="end">
        <Text>{formatCurrency(total_tax_amount_calculated)}</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{statusBadges[status] || null}</Text>
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

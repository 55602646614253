import { Button, Flex, Text } from '@chakra-ui/react';
import { ArrowRightIcon, PartnerOrgIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import { PARTNER_ORG } from 'constants/AppRoutes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type PartnerOrgSelectorProps = {
  isExpanded: boolean;
};

export const PartnerOrgSelector = ({ isExpanded }: PartnerOrgSelectorProps) => {
  const navigate = useNavigate();

  if (!isExpanded) {
    return (
      <Tooltip content="View Organizations" positioning={{ placement: 'bottom-end' }}>
        <Button
          bgColor={'primary.500'}
          asChild
          flexWrap={'nowrap'}
          color={'gray.900'}
          justifyContent={'space-between'}
          height={'36px'}
          width={'unset'}
          p={1}
          borderRadius={'4px'}
          mx={2}
          style={{ width: 'unset' }}
          onClick={() => navigate(PARTNER_ORG)}
        >
          <Flex alignItems={'center'}>
            <PartnerOrgIcon />
            <ArrowRightIcon height={'10px'} width={'10px'} />
          </Flex>
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      bgColor={'primary.500'}
      asChild
      flexWrap={'nowrap'}
      color={'gray.900'}
      justifyContent={'space-between'}
      height={'36px'}
      width={'auto'}
      p={3}
      borderRadius={'4px'}
      mx={2}
      onClick={() => navigate(PARTNER_ORG)}
    >
      <Flex alignItems={'center'}>
        <Text>Organizations</Text>
        <ArrowRightIcon height={'10px'} width={'10px'} />
      </Flex>
    </Button>
  );
};

import { ApideckVault, Connection } from '@apideck/vault-js';
import { useAuthInfo } from '@propelauth/react';
import { useMutation } from '@tanstack/react-query';
import { createApiDeckConnection, deleteApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import getValue from 'lodash/get';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ItemCard } from '../item-card';

type HrisTabProps = {
  serviceId: string;
  heading: string;
  subHeading: string;
  icon: React.ReactNode;
  isConnected?: boolean;
};

export const HrisTab = ({ serviceId, heading, subHeading, icon, isConnected = false }: HrisTabProps) => {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const { user } = useAuthInfo();
  const [connectionData, setConnectionData] = useState({
    consumer_id: '',
  });
  const { handleFailNotification } = useHandleNotification();
  const { handleSuccessNotification } = useHandleNotification();
  const navigate = useNavigate();

  // TODO: remove after prod release of rippling apps
  const hrisEnabled = getValue(user, 'properties.metadata.hris');

  const { mutateAsync: doUpdateApideckConnections, isPending } = useMutation({
    mutationFn: (status: string) => {
      return updateApiDeckConnections(connectionData.consumer_id, orgId, status);
    },
    onSuccess: () => {
      handleSuccessNotification('HRIS Connected SuccessFully.');
      navigate('/source');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const handleConnectionChange = async (connection: Connection) => {
    if (connection.state === 'callable') {
      await doUpdateApideckConnections('ACTIVE');
      track('connects integration', { integration: 'hris', status: 'success' });
    }
    if (connection.state === 'added' && connectionData.consumer_id) {
      updateApiDeckConnections(connectionData.consumer_id, orgId, 'INACTIVE');
      track('connects integration', { integration: 'hris', status: 'failed' });
    }
  };

  const { mutate: doCreateApideckConnection, isPending: isCreating } = useMutation({
    mutationFn: async () => {
      const { data } = await createApiDeckConnection(orgId, serviceId, {});
      return data;
    },
    onSuccess: data => {
      const token = data['token'];
      const connectionId = data['connection_id'];
      setConnectionData({ ...connectionData, consumer_id: connectionId });

      ApideckVault.open({
        token: token,
        unifiedApi: 'hris',
        serviceId,
        onConnectionChange: handleConnectionChange,
        onClose: () => {
          deleteApiDeckConnection(connectionId, orgId);
          setConnectionData({ consumer_id: '' });
        },
      });
    },
  });

  // TODO: remove after prod release of rippling app
  if (!hrisEnabled && serviceId === 'rippling') {
    return null;
  }

  return (
    <ItemCard
      isConnected={isConnected}
      title={heading}
      description={subHeading}
      icon={icon}
      onConnect={doCreateApideckConnection}
      btnProps={{
        loading: isCreating || isPending,
        colorPalette: 'gray',
      }}
    />
  );
};

import { TableFilterPopover } from 'components/filter';
import { PopoverContentProps } from 'components/ui/popover';
import { useState } from 'react';
import { FilingHistoryFiltersType } from 'types/filing';

import FilterContent from './filter-content';

type FilingFilterButtonProps = {
  popoverContentProps?: PopoverContentProps;
  tableFilters: FilingHistoryFiltersType;
  updateFilters: (newValue: Partial<FilingHistoryFiltersType>) => void;
};

export const FilterButton = ({ tableFilters, popoverContentProps, updateFilters }: FilingFilterButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { country_code, state_code, start_date, end_date } = tableFilters;
  const hasFilter = !!(country_code || state_code || start_date || end_date);

  return (
    <TableFilterPopover
      hasFilter={hasFilter}
      open={open}
      onOpenChange={({ open }) => setOpen(open)}
      popoverContentProps={popoverContentProps}
    >
      <FilterContent
        tableFilters={tableFilters}
        updateFilters={updateFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </TableFilterPopover>
  );
};

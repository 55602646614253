import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_HISTORY_STATE_KEY, getFilings } from 'apis/filing-apis';
import { PaginationButtons } from 'components/pagination/pagination';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { FilingHistoryFiltersType } from 'types/filing';

import { FilingHistoryTable } from './filing-history-table';

type FilingHistoryTableProps = {
  tableFilters: FilingHistoryFiltersType;
  updateOrder: (order: string[]) => void;
};

const FilingsHistoryTable = ({ tableFilters, updateOrder }: FilingHistoryTableProps) => {
  const { orgId } = useOrg();

  const [{ page, size }, setPagination] = useState<{ page: number; size: number }>({
    page: 1,
    size: 25,
  });
  const { state_code, start_date, end_date, country_code, date_filed__lte, status__in, order_by } = tableFilters;

  const { isPending, data } = useQuery({
    queryKey: [
      FILINGS_HISTORY_STATE_KEY,
      orgId,
      page,
      size,
      state_code,
      start_date,
      end_date,
      country_code,
      date_filed__lte,
      status__in,
      order_by,
    ],
    queryFn: async () => {
      const res = await getFilings({
        orgId,
        params: {
          page,
          size,
          state_code,
          start_date,
          end_date,
          country_code,
          date_filed__lte,
          status__in,
          order_by: order_by.join(','),
        },
      });
      return res?.data || [];
    },
  });

  const handlePagination = async (page: number, size: number) => {
    setPagination({ page, size });
  };

  const filingData = data?.items || [];
  const isDataEmpty = !filingData?.length;
  const totalPages = data?.pages ?? 0;

  return (
    <Stack height={'100%'}>
      <FilingHistoryTable isPending={isPending} data={filingData} updateOrder={updateOrder} />
      {!isDataEmpty && (
        <PaginationButtons
          size={size}
          currentPage={page}
          totalPages={totalPages}
          onPageClick={handlePagination}
          onSizeChange={handlePagination}
        />
      )}
    </Stack>
  );
};

export default FilingsHistoryTable;

import { Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { KintsugiLogo } from 'components/icons';
import { DrawerContent, DrawerRoot } from 'components/ui/drawer';
import { FiMenu } from 'react-icons/fi';

import { Sidebar } from '.';

export const MobileSubNav = ({ initialDrawerOpen }: { initialDrawerOpen: boolean }) => {
  const { open, onOpen, onClose, onToggle } = useDisclosure({
    defaultOpen: initialDrawerOpen,
  });

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      justifyContent={'space-between'}
    >
      <KintsugiLogo />
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu">
        <FiMenu />
      </IconButton>
      <DrawerRoot open={open} placement="start" onPointerDownOutside={onToggle}>
        <DrawerContent>
          <Sidebar onClose={onClose} hideToggleBtn />
        </DrawerContent>
      </DrawerRoot>
    </Flex>
  );
};

import { Flex, Icon, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { MdCheck } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type OnboardingStepProps = {
  icon: any;
  title: string;
  description: string;
  isCompleted: boolean;
  nextStep: boolean;
  path: string;
  disabled: boolean;
  onClose: () => void;
};

export const OnboardingStep = ({
  icon,
  title,
  description,
  isCompleted,
  nextStep,
  path,
  disabled,
  onClose,
}: OnboardingStepProps) => {
  const navigate = useNavigate();

  const renderButtonContent = () => {
    if (isCompleted) {
      return (
        <Flex justifyContent={'center'} width={'106px'} pl={{ sm: 4 }}>
          <Flex bgColor="secondary.50" w="8" h="8" borderRadius="full" justify="center" align="center">
            <Icon w="5" h="5" color="secondary.500">
              <MdCheck />
            </Icon>
          </Flex>
        </Flex>
      );
    }

    const buttonText = title.includes('Import') ? 'Import' : 'Add';
    return (
      <Tooltip content="Please contact an owner in your organization to add these details." disabled={!disabled}>
        <Button
          ml={{ sm: 12 }}
          width={'106px'}
          variant={nextStep && !disabled ? 'solid' : 'outline'}
          colorPalette={nextStep && !disabled ? 'blue' : 'gray'}
          onClick={() => {
            navigate(path);
            onClose();
          }}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Flex
      justifyContent={'space-between'}
      align={{ base: 'center', md: 'center', sm: 'flex-start' }}
      direction={{ base: 'row', md: 'row', sm: 'column' }}
      gap={{ base: '0px', md: '0px', sm: '10px' }}
    >
      <Flex gap={'16px'}>
        <Flex w="42px" h="42px" justify="center" align="center" borderRadius="full" bgColor="gray.50">
          <Icon w="6" h="6">
            {icon}
          </Icon>
        </Flex>

        <Flex direction={'column'}>
          <Text fontWeight={'500'} fontSize={'sm'}>
            {title}
          </Text>
          <Text fontWeight={'400'} fontSize={'12px'}>
            {description}
          </Text>
        </Flex>
      </Flex>
      {renderButtonContent()}
    </Flex>
  );
};

import { Icon } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { MdOutlineInfo } from 'react-icons/md';

type VDAProcessNeededProps = {
  vda: boolean;
  isRegistered: boolean;
};

export const VDAProcessNeeded: React.FC<VDAProcessNeededProps> = ({ vda, isRegistered }) => {
  if (!vda || isRegistered) {
    return null;
  }

  return (
    <Tooltip
      positioning={{ placement: 'bottom-end' }}
      content="This request includes a VDA process, and the registration process may take longer than usual to complete."
    >
      <Icon display="flex" w="3.5" h="3.5">
        <MdOutlineInfo title="info" />
      </Icon>
    </Tooltip>
  );
};

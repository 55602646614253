import { Flex, Text } from '@chakra-ui/react';
import { useOrg } from 'hooks/useOrg';

export const PartnerOrgName = () => {
  const { name } = useOrg();
  return (
    <Flex border={'1px'} borderColor={'#333854'} px={3} py={2} borderRadius={'4px'} mt={4}>
      <Text lineClamp={1} color="#fff" title={name} fontSize={'14px'}>
        {name}
      </Text>
    </Flex>
  );
};

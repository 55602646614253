import { createListCollection, Flex, HStack, Input } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'apis/api';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { Address, AddressSuggestionPopover } from 'components/Address/AddressSuggestionPopover';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Customer, customerUpdateSchema } from 'types/customers';
import { LocationOptionType } from 'types/location';
import { CountryCodeEnum } from 'types/shared-types';
import { toNormalCase } from 'utils';

import { AddressStatus } from './address-status';

type EditAddressModalProps = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
};

export const EditCustomerAddressModal = ({ customer, isOpen, onClose }: EditAddressModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { countries, getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);
  const { mutateAsync: doUpdateCustomerAddress, isPending } = useMutation<
    Customer,
    unknown,
    {
      orgId: string;
      customerId: string;
      payload: Partial<Customer>;
    }
  >({
    mutationFn: async ({ orgId, customerId, payload }) => {
      const res = await api.put(
        `/v1/customers/${customerId}`,
        { ...payload, organization_id: orgId },
        {
          headers: {
            'x-organization-id': orgId,
          },
        }
      );
      return res.data;
    },
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: [CUSTOMERS_STATE_KEY, orgId, data.id],
      });
      handleSuccessNotification('Customer address updated successfully');
    },
  });

  const initialValues = customerUpdateSchema
    .transform(({ city, ...rest }) => ({
      city: toNormalCase(city),
      ...rest,
    }))
    .cast(customer, { stripUnknown: true });

  const { errors, values, touched, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: customerUpdateSchema,
    onSubmit: async payload => {
      await doUpdateCustomerAddress({ payload, orgId, customerId: customer.id });
      onClose();
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(values.country ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [values.country]);

  const handleUpdateAddress = (address: Address) => {
    setFieldValue('city', toNormalCase(address.city || ''));
    setFieldValue('state', address.state);
    setFieldValue('postal_code', address.postal_code);
  };

  const onCloseModal = () => {
    resetForm();
    onClose();
  };

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);

  const statesCollection = useMemo(() => {
    return createListCollection({
      items: stateOptions,
    });
  }, [stateOptions]);

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
      size={'xl'}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef} colorPalette="blue">
        <DialogCloseTrigger />
        <DialogHeader>
          <AddressStatus
            title="Edit Address"
            customer={customer}
            titleProps={{
              fontSize: '18px',
              lineHeight: '26px',
              fontWeight: 'medium',
              color: 'gray.900',
            }}
          />
        </DialogHeader>
        <DialogBody>
          <Flex direction={'column'} gap={4}>
            <HStack align={'baseline'} gap={4}>
              <Field
                label="Country"
                invalid={!!(errors.country && touched.country)}
                errorText={errors.country}
                required
              >
                <SelectRoot
                  collection={countriesCollection}
                  value={values.country ? [values.country] : undefined}
                  onValueChange={({ value }) => {
                    setFieldValue('country', value[0]);
                  }}
                >
                  <SelectTrigger>
                    <SelectValueText />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {countriesCollection.items.map(item => (
                      <SelectItem item={item} key={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
              <Field label="State" invalid={!!(errors.state && touched.state)} errorText={errors.state} required>
                <SelectRoot
                  collection={statesCollection}
                  value={values.state ? [values.state] : undefined}
                  onValueChange={({ value }) => {
                    setFieldValue('state', value[0]);
                  }}
                >
                  <SelectTrigger>
                    <SelectValueText />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {statesCollection.items.map(item => (
                      <SelectItem item={item} key={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              <Field label="City" invalid={!!(errors.city && touched.city)} errorText={errors.city} required>
                <Input as="input" id="city" type="text" name="city" onChange={handleChange} value={values.city} />
              </Field>
              <Field
                label="Postal Code"
                invalid={!!(errors.postal_code && touched.postal_code)}
                errorText={errors.postal_code}
                required
              >
                <Input
                  id="postal_code"
                  type="string"
                  name="postal_code"
                  onChange={handleChange}
                  value={values.postal_code}
                />
              </Field>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              <Field
                label="Address Line 1"
                invalid={!!(errors.street_1 && touched.street_1)}
                errorText={errors.street_1}
              >
                <Input
                  as="input"
                  id="street_1"
                  type="text"
                  name="street_1"
                  onChange={handleChange}
                  value={values.street_1}
                />
              </Field>
              <Field label="Address Line 2">
                <Input
                  as="input"
                  id="street_2"
                  type="text"
                  name="street_2"
                  onChange={handleChange}
                  value={values.street_2}
                />
              </Field>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              {isOpen && (
                <AddressSuggestionPopover
                  address={{
                    ...values,
                    city: values.city,
                    country: values.country as CountryCodeEnum,
                    state: values.state,
                    county: values.county || undefined,
                    postal_code: values.postal_code,
                  }}
                  onUpdateAddress={handleUpdateAddress}
                />
              )}
            </HStack>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => handleSubmit()}
              loading={isPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

import { Box, BoxProps, Flex, SkipNavContent, SkipNavLink } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import IntercomWidget from 'components/intercom';
import { OnboardingStepper } from 'components/onboarding-stepper';
import { MobileSubNav } from 'components/Sidebar/sidebar.sm';
import { APP_ROUTES } from 'constants/app-constants';
import { useOrg } from 'hooks/useOrg';
import usePageTracking from 'hooks/usePageTracking';
import useUpdateDocTitle from 'hooks/useUpdateDocTitle';
import PartnerOrg from 'pages/PartnerOrg';
import React from 'react';

import AppDrawers from './app-drawers';
import AppPopups from './app-popups';

const App = ({ children, ...rest }: BoxProps) => (
  <Box h="100vh" {...rest}>
    {children}
  </Box>
);
const AppBody = ({ children, ...rest }: BoxProps) => (
  <Box h="100%" overflow="auto" {...rest}>
    {children}
  </Box>
);
const AppContent = ({ children, ...rest }: BoxProps) => (
  <Box height="calc(100% - 3rem)" overflow="auto" {...rest}>
    {children}
  </Box>
);

const FallbackComponent = () => <ErrorFallback />;

export const AppLayoutSm = (props: { children: React.ReactNode }): React.ReactNode => {
  const authInfo = useAuthInfo();
  usePageTracking();
  useUpdateDocTitle(APP_ROUTES);
  const { orgId } = useOrg();

  if (!authInfo || !authInfo.user) {
    return <FallbackComponent />;
  }

  // not to render app content until org selected to avoid edge cases
  if (!orgId) {
    return (
      <>
        <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>

        <App>
          <ErrorBoundary fallback={FallbackComponent} onError={(error: Error) => console.error(error)}>
            <Box>
              <MobileSubNav initialDrawerOpen={true} />
            </Box>
            <AppBody px={4} w="100%">
              <SkipNavContent />
              {!!authInfo?.user?.properties?.partner_id && (
                <AppContent height={'100%'}>
                  <PartnerOrg />
                </AppContent>
              )}
            </AppBody>
            <Box position="fixed" bottom="10px" right="10px" padding="10px"></Box>
          </ErrorBoundary>
        </App>
      </>
    );
  }

  return (
    <>
      <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>

      <App>
        <Box>
          <MobileSubNav initialDrawerOpen={false} />
        </Box>
        <AppBody px={4} w="100%" pb={4}>
          <SkipNavContent />
          <AppContent>{props.children}</AppContent>
        </AppBody>
        <AppPopups />
        <AppDrawers />
        <Flex
          zIndex={1000}
          position="fixed"
          width={{ base: '13.25rem', sm: '100%', md: '13.25rem', lg: '13.25rem' }}
          bottom="10px"
          align={'center'}
        >
          <OnboardingStepper />
        </Flex>
        <Flex
          zIndex={1000}
          flexDir={'column'}
          position="fixed"
          bottom="10px"
          right="16px"
          padding="10px"
          gap={4}
          align={'center'}
        >
          <IntercomWidget />
        </Flex>
      </App>
    </>
  );
};

export default AppLayoutSm;

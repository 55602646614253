import { createSystem, defaultConfig } from '@chakra-ui/react';
import { badgeRecipe } from 'component-library/recipes/badge';
import { buttonRecipe } from 'component-library/recipes/button';
import { iconRecipe } from 'component-library/recipes/icon';
import { dialogSlotRecipe } from 'component-library/slot-recipes/dialog';
import { tableSlotRecipe } from 'component-library/slot-recipes/table';
import { tabsSlotRecipe } from 'component-library/slot-recipes/tabs';
import { toastSlotRecipe } from 'component-library/slot-recipes/toast';
import { tooltipSlotRecipe } from 'component-library/slot-recipes/tooltip';

import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { shadows } from './foundations/shadows';
import { typography } from './foundations/typography';
import { globalCss } from './styles';

export default createSystem(defaultConfig, {
  cssVarsPrefix: 'ks',
  globalCss,
  theme: {
    ...breakpoints,
    semanticTokens: {
      shadows,
      colors: {
        fg: {
          DEFAULT: { value: '{colors.gray.800}' },
        },
        blue: {
          solid: { value: '{colors.secondary.500}' },
          fg: { value: '{colors.secondary.500}' },
          muted: { value: '{colors.secondary.50}' },
          subtle: { value: '{colors.secondary.50}' },
        },
        border: {
          DEFAULT: { value: '{colors.gray.50}' },
        },
        gray: {
          contrast: { value: '{colors.black}' },
          solid: { value: '{colors.gray.50}' },
          subtle: { value: '{colors.gray.50}' },
        },
        green: {
          fg: { value: '{colors.green.500}' },
          subtle: { value: '{colors.green.50}' },
        },
        red: {
          fg: { value: '{colors.red.500}' },
          subtle: { value: '{colors.red.50}' },
        },
      },
    },
    recipes: {
      badge: badgeRecipe,
      button: buttonRecipe,
      icon: iconRecipe,
    },
    slotRecipes: {
      dialog: dialogSlotRecipe,
      tooltip: tooltipSlotRecipe,
      tabs: tabsSlotRecipe,
      table: tableSlotRecipe,
      toast: toastSlotRecipe,
    },
    tokens: {
      ...typography,
      colors,
      // config: {
      //   cssVarPrefix: 'ks',
      //   initialColorMode: 'light',
      //   useSystemColorMode: false,
      // },
    },
  },
});

import api from './api';

const createShopifyConnection = (shop_id: string, shop_url: string, orgId: string) => {
  return api.put(
    `/v1/connections/shopify_oauth/${shop_id}`,
    {
      url: `${shop_url}`,
      secret: '',
    },
    {
      method: 'PUT',
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

export { createShopifyConnection };
